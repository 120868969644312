export default {

    
    

    baseUrl:'https://api.ooridoo.com/'
    // baseUrl:"http://localhost:9000/",
    
    // baseUrl:"https://pyurelyecommerce.pythonanywhere.com/api",
    // http://5.104.83.184:9000/
    // imgUrl:"https://pyurelyecommerce.pythonanywhere.com/media/"
    

    



    

}
